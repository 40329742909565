/* eslint-disable import/extensions */
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container } from "react-bootstrap";
import Div100vh from "react-div-100vh";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import Navigation from '../components/Navigation/Navigation';
import COLORS from '../styles/COLORS';
import "./index.css";

export default function MainLayout({ children, location, title, postView }) {

  const NavigationBar = () => <Navigation location={location} title={title} postView={postView} />

  return (
    <Div100vh style={{overflow: postView && "hidden"}}>
    <Container
      className="Container_Main"
      fluid
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        OverflowX: "hidden",
        color: COLORS.lightGrey,
      }}
    >
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <html lang="en" />
      </Helmet>
      {NavigationBar()}
      {children}
    </Container>
    </Div100vh>
  );
}
