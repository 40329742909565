const mainColor = "#831dff";

const COLORS = {
  heroGradient: `linear-gradient(45deg, ${mainColor} 0%, #02bbfe 80%)`,
  main: {
    default: mainColor,
    deep: "#5a4e70",
    rollover: "#817bf2",
    cadence: "#a694ff",
  },
  white: "white",
  mellowYellow: "#f9d670",
  mediumGrey: "#333333",
  lightGrey: "#acacac",
  perfectGrey: "#e1dfdf",
  darks: {
    base: "#121212",
    raised: {
      r100: "#1f1f1f",
      r200: "#2c2c2c",
    },
  },
};
module.exports = COLORS;
