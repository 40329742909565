import AniLink from "gatsby-plugin-transition-link/AniLink";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import Logo from "../../../static/SVG/logo-svg.svg";
import DIMENSIONS from "../../helpers/DIMENSIONS";
import useWindowDimensions from "../../helpers/GetWindowDimensions";
import * as S from "../../styles/styles.css";

function Navigation({ location, title, postView }) {
  const { width } = useWindowDimensions();
  const [mobileMenuShow, setMobileMenuShow] = useState(false);

  const mobileBreakpoint = DIMENSIONS.breakpoints.mobile;

  return (
    <>
      <S.NavRow className="NavigationRow" noGutters>
        <Col xs={6} sm={4} md={6}>
          <AniLink to="/">
            <span>
              <Logo width="42" />
            </span>
            <h1 style={{ color: "black", whiteSpace: "nowrap"}}>Sebastian Grant</h1>
          </AniLink>
        </Col>
        <S.NavLinksCol xs={6} md={6} sm={8}>
          {width > mobileBreakpoint ? (
            <>
              <S.NavLink to="/about">
                <S.NavButton active={location === "about"}>About</S.NavButton>
              </S.NavLink>
              <S.NavLink to="/">
                <S.NavButton active={location === "artworks"}>
                  Artworks
                </S.NavButton>
              </S.NavLink>
              <S.NavLink to="/music">
                <S.NavButton active={location === "music"}>Music</S.NavButton>
              </S.NavLink>
              <S.NavLink to="/software">
                <S.NavButton active={location === "software"}>
                  Software
                </S.NavButton>
              </S.NavLink>
              <S.NavLink to="/outdoor">
                <S.NavButton active={location === "outdoor"}>
                  Outdoor
                </S.NavButton>
              </S.NavLink>
            </>
          ) : (
            <>
              <S.MobileMenu>
                <HiOutlineMenuAlt2
                  size={36}
                  style={{ marginRight: postView ? "23px" : "23px" }}
                  onClick={() => {
                    setMobileMenuShow(true);
                  }}
                />
              </S.MobileMenu>
              {mobileMenuShow && (
                <S.MobileMenuOpenDiv>
                  <S.MobileMenu showing={mobileMenuShow}>
                    <MdClose
                      style={{ marginRight: postView ? "23px" : "23px" }}
                      size={36}
                      onClick={() => {
                        setMobileMenuShow(false);
                      }}
                    />
                  </S.MobileMenu>
                  <div className="d-flex flex-column">
                    <S.NavLink to="/about">
                      <S.NavButton active={location === "about"}>
                        About
                      </S.NavButton>
                    </S.NavLink>
                    <S.NavLink to="/">
                      <S.NavButton active={location === "artworks"}>
                        Artworks
                      </S.NavButton>
                    </S.NavLink>
                    <S.NavLink to="/music">
                      <S.NavButton active={location === "music"}>
                        Music
                      </S.NavButton>
                    </S.NavLink>
                    <S.NavLink to="/software">
                      <S.NavButton active={location === "software"}>
                        Software
                      </S.NavButton>
                    </S.NavLink>
                    <S.NavLink to="/outdoor">
                      <S.NavButton active={location === "outdoor"}>
                        Outdoor
                      </S.NavButton>
                    </S.NavLink>
                  </div>
                </S.MobileMenuOpenDiv>
              )}
            </>
          )}
        </S.NavLinksCol>
      </S.NavRow>
      {!postView && (
        <S.HeadingRow>
          <Col>
            <S.ListingTitle>
              <h3>{title}</h3>
            </S.ListingTitle>
          </Col>
        </S.HeadingRow>
      )}
    </>
  );
}

export default Navigation;
