const config = {
    siteTitle: "Sebastian Grant", // Site title.
    siteTitleShort: "Seb Grant", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteTitleAlt: "Sebastian Grant - Fine Art", // Alternative site title for SEO.
    siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
    siteUrl: "https://sebastiangrant.com", // Domain of your website without pathPrefix.
    pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
    siteDescription: "Fine Art - Software Design - Music - Works.", // Website description used for RSS feeds/meta description tag.
    siteRss: "/rss.xml", // Path to the RSS file.
    siteRssTitle: "Sebastian Grant RSS", // Title of the RSS feed
    siteFBAppID: "1825356251115265", // FB Application ID for using app insights
    googleAnalyticsID: "UA-45425714-1", // GA tracking ID.
    disqusShortname: "sebastiangrant-com", // Disqus shortname.
    dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
    dateFormat: "DD/MM/YYYY", // Date format for display.
    postsPerPage: 36, // Amount of posts displayed per listing page.
    userName: "Sebastian Grant", // Username to display in the author segment.
    userEmail: "sebastianlgrant@gmail.com", // Email used for RSS feed's author segment
    userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
    userLocation: "North Coast NSW, Australia", // User location to display in the author segment.
    userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
    userDescription: "Artist and Designer.", // User description to display in the author segment.
    // Links to social profiles/projects you want to display in the author segment/navigation bar.
    userLinks: [{
            label: "Facebook",
            url: "https://www.facebook.com/sebastiangrantart",
            iconClassName: "fa fa-facebook",
        },
        {
            label: "Email",
            url: "mailto:sebastianlgrant@gmail.com",
            iconClassName: "fa fa-envelope",
        },
    ],
    copyright: "Copyright © 2021 Sebastian Grant", // Copyright string for the footer of the website and RSS feed.
    themeColor: "#c62828", // Used for setting manifest and progress theme colors.
    backgroundColor: "#e0e0e0", // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
    config.pathPrefix = "";
} else {
    // Make sure pathPrefix only contains the first forward slash
    config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
    config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
    config.siteRss = `/${config.siteRss}`;

module.exports = config;