import { Link } from "gatsby";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import DIMENSIONS from "../helpers/DIMENSIONS";
import COLORS from "./COLORS";

export const SocialLinks = styled.div`
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
width: 100%;

  button {
    
    svg{
      filter: saturate(0) contrast(1.4);
    }
  }
`;

const transitionDuration = "0.3s";

export const StandardContainer = styled(Container)`
  h3 {
    color: black;
  }
  p {
    font-size: 1.3em;
  }
`;

export const InfoButton = styled.div`
  display: block;
  color: ${(props) => (!props.open ? "black" : "white")};
  background: ${(props) => (props.open ? "black" : `${COLORS.perfectGrey}`)};
  position: fixed;
  top: 115px;
  transition: padding, box-shadow, 0.5s ease;
  right: -10px;
  padding: ${(props) => (!props.open ? "10px" : "13px 13px")};
  z-index: 1000;
  border: ${(props) =>
    !props.open ? "solid 2px black" : "solid 2px transparent"};
  border-right: solid 2px transparent !important;
  border-radius: 100%;
  box-shadow: ${(props) =>
    props.open ? "#00000090 4px 4px 4px" : "#00000000 4px 4px 4px"};

  p {
    margin: 0;
    padding: 0;
    letter-spacing: 0.1em;
  }

  @media screen and (min-width: ${DIMENSIONS.breakpoints.mobile}px) {
    display: none;
  }
`;

export const PostCard = styled(Card)`
  border-radius: 0 !important;
  border: 0 !important;
  border-top: solid 6px black !important;
  border-bottom: solid 6px black !important;
  background: ${COLORS.perfectGrey};

  .card-title {
    h4 {
      color: black;
    }
    h5.post-category {
      font-style: italic;
      margin-top: -0.4em;
      font-size: 0.92em;
      color: black;
    }
  }

  .card-body {
    p,
    h6 {
      color: black;
    }
  }

  .card-footer {
    text-align: left;
  }

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
  }
`;

export const PostCol = styled(Col)`
  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    position: fixed;
    top: 52px;
    height: 101.3%;
    z-index: 99;
    background: white;
    backdrop-filter: blur(10px);
    opacity: ${(props) => !props.open && "0"};
    pointer-events: ${(props) => !props.open && "none"};
  }
`;

export const NavRow = styled(Row)`
  background: white;
  margin-left: -15px;
  min-width: 100vw;
  & > div > a {
    display: flex;
    align-items: center;
  }
  /* Logo Text Style */
  & > div > a > span > svg {
    padding: 0.1em;
    margin-top: -3px;
  }

  & > div > a > h1 {
    color: white;
    margin-left: 0.3em;
    font-size: 1.57rem;
    padding: 0.72rem 0;
    margin-top: 2px;

    @media screen and (max-width: 996px) {
      font-size: 1.5em;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.11em;
    }

    @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
      font-size: 1.24em;
      max-height:48px;
    }

    @media screen and (max-width: 321px) {
      font-size: 0.72em;
    }
  }

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    border-right: none !important;
  }
`;

export const NavLinksCol = styled(Col)`
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;

  & > a {
    flex: 1;
  }

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    justify-content: center;
    align-items: center;
  }
`;

export const CatLinksCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;

  & > a {
    flex: 1;
  }
`;

export const SubMenuButton = styled(Button)`
  height: 40px;
  width: 40px;
  margin-left: 15px;
  margin-top: 1px;
  margin-right: -15px;
  background: transparent;
  border: none;
  color: #000000;
  overflow: hidden;

  &:hover,
  &:active {
    background: ${COLORS.main.default};
    color: white;
  }

  &:focus {
    background: transparent;
    outline: 0;
    box-shadow: none;
    color: #000000;
  }
`;

export const SubMenuCol = styled(Col)`
  width: ${(props) => props.closed && "0"};
  margin: ${(props) => props.closed && "0 0"};
  padding: ${(props) => (props.closed ? "0 0" : "0 2rem")};
  overflow: hidden;
  background: transparent;
`;

export const NavLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  &.activeLink > .catButton {
    border: solid 0.167rem ${COLORS.main.default};
    transition: border-color 1s ease;
    color: black !important;
  }
`;

export const CategoryButton = styled(Button)`
  background: white;
  border-radius: 20px;
  border: solid 0.167rem grey;
  font-size: calc(1.57rem * 0.618);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: relative;
  text-decoration: none !important;
  color: ${COLORS.main.cadence};
  transition: border-color 1s ease;
  margin-bottom: 0.618rem;

  &:hover {
    background: ${COLORS.main.cadence};
    color: white;
  }

  &:active,
  &:focus,
  &:visited {
    background: ${COLORS.darks.base} !important;
    text-decoration: none !important;
  }

  @media screen and (max-width: 996px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.88rem;
  }

  @media screen and (max-width: 575px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 321px) {
    font-size: 1rem;
  }
`;

export const NavButton = styled.button`
  background: white !important;
  color: black;
  min-height: ${(props) => (!props.active ? "63px" : "58px")};
  padding-top: ${(props) => (!props.active ? "" : "5px")};
  flex: 1;
  border: none;
  font-size: calc(1.57rem * 0.618);
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0;
  position: relative;
  text-decoration: none !important;
  font-size: 0.9em;
  position: relative;
  outline: 0;

  &:hover {
    color: #051629 !important;
  }

  @media screen and (max-width: 996px) {
    font-size: 0.7em;
  }

  &::after {
    ${(props) =>
      props.active &&
      `
    content: "";
    display: block;
    background: black;
    width: 100%;
    position: absolute;
    bottom: -9px;
    left: 0;
    border-radius: 0;
    height: 10px;
    mix-blend-mode: darken;
    color: rgb(31, 31, 31);
    
    `}
    @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
      display: none;
    }
  }

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    margin-left: 20px;
  }

  &:active,
  &:focus,
  &:visited {
    background: white !important;
    text-decoration: none !important;
  }
`;

export const MobileMenu = styled.div`
  position: absolute;
  cursor: pointer;
  padding: 6px 0 6px 13px;
  margin: 2px 0 2px 2px;
  top: 0;
  right: ${(props) => (!props.showing ? "0" : "0")};
  background: ${(props) => (!props.showing ? "#FFFFFF10" : "transparent")};
  border-radius: 5px;

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    top: 0px;
    right: -8px;
  }
`;

const MenuReveal = keyframes`
from{
margin-left: 100vw;
}
to{
margin-left: 0;
}
`;

export const MobileMenuOpenDiv = styled.div`
  z-index: 99;
  width: 100vw;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 5px 5px #00000040;
  padding-bottom: 1rem;
  background: ${COLORS.white};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  animation: ${MenuReveal} ${transitionDuration} ease-out;
`;

export const HeadingRow = styled(Row)``;

const fadeIn = keyframes`
from{
opacity: 0;
}
to{
opacity: 1
}
`

export const ListingTitle = styled.div`
  padding-left: 0.5rem;
  opacity: 0;
  animation: ${fadeIn} 1s forwards;

  @media screen and (max-width: 768px) {
    h3 {
      font-size: 1.11em;
    }
  }

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    display: none;
  }
`;

export const GalleryRow = styled(Row)`
  flex: 1;
  padding-top: 15px;
  background: #fafafa;
`;

export const GalleryDate = styled.div`
  position: absolute;
  bottom: 0;
  right: 0.3rem;
  padding: 0.6rem 1rem;
  color: ${COLORS.lightGrey};
  font-weight: 100;
`;

export const GalleryRowInnerDiv = styled.div`
  /* Empty Style */
`;

// Pagination Styles

export const PagingRow = styled(Row)`
  background: linear-gradient(grey, #121212);
  padding: 0.3em 0 0.3em 0;
  min-height: 130px;
  justify-items: stretch;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    min-height: 88px;
  }
`;

export const PagingRowLink = styled(Link)`
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 !important;
`;

export const PagingRowButton = styled(Button)`
  flex: 1;
  margin: 0.3rem 1rem;
  all: unset;
  width: 100%;
  color: white;
  border: solid 1px transparent;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  height: 30px;
  align-items: center;
  text-align: center;
  background-color: ${COLORS.darks.raised.r200};

  &:visited {
    background-color: ${COLORS.main.default} !important;
    border: solid 1px transparent;
  }

  &:focus {
    background-color: ${COLORS.main.default} !important;
    border: solid 1px transparent;
  }

  &:hover {
    background-color: ${COLORS.main.default} !important;
    border: solid 1px transparent;
  }

  &:active {
    background-color: ${COLORS.main.default} !important;
  }

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
`;

export const PageNumber = styled(Button)`
  all: unset;
  color: white;
  border: solid 1px transparent;
  background-color: ${COLORS.darks.raised.r200};
  border-radius: 5px;
  height: 30px;
  text-align: center;
  outline: none;
  flex: 1;
  margin: 0.3rem 1rem;

  &:visited {
    background-color: ${COLORS.main.default} !important;
    border: solid 1px transparent;
  }

  &:focus {
    background-color: ${COLORS.main.default} !important;
    border: solid 1px transparent;
  }

  &:hover {
    background-color: ${COLORS.main.default} !important;
    border: solid 1px transparent;
  }

  &:active {
    background-color: ${COLORS.main.default} !important;
  }

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
`;

// Individual Post Card

export const GalleryWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  animation: ${fadeIn} 1s forwards;

  & > a {
    width: 33.3333333333%;
    padding: 1px;

    @media screen and (min-width: 1920px) {
      min-height: 42rem;
      width: 20%;
      padding: 2%;
    }

    @media screen and (min-width: 1570px) and (max-width: 1919px) {
      min-height: 42rem;
      width: 25%;
      padding: 0 3%;
    }

    &:hover {
      text-decoration: none;
    }
    .card-footer {
      @media screen and (max-width: 375px) {
        margin: 0;
        padding: 0;
      }
    }

    .title {
      color: ${COLORS.main.cadence};
      transition: text-shadow, color, ${transitionDuration} ease;

      @media screen and (max-width: 575px) {
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @media screen and (max-width: 375px) {
        font-size: 0.88rem;
        white-space: nowrap;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .category {
      color: ${COLORS.lightGrey};
      @media screen and (max-width: 575px) {
        font-size: 0.88rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @media screen and (max-width: 375px) {
        font-size: 0.7rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .date {
      @media screen and (max-width: 575px) {
        font-size: 0.9rem;
      }
      @media screen and (max-width: 375px) {
        font-size: 0.66rem;
        margin: 0;
        padding: 0;
        right: -0.66rem;
        position: relative;
      }
    }
  }
`;

// The Gallery Card

export const GalleryCard = styled(Card)`
  /* Calculated width make Responsive */
  width: 100%;
  background: ${COLORS.darks.raised.r100};
  transition: background ${transitionDuration} ease;
  min-height: 39.5rem;
  border: none;

  .card-title {
    font-size: 1.2rem;
  }

  .category {
    font-size: 0.96rem;
  }

  .card-body{
	  height: 150px;
  }

  img {
    box-shadow: 0 3px 0px black;
    border-top: 1px solid grey;
  }

  /* Row of 3 */

  @media screen and (max-width: 1200px) {
    min-height: unset;
  }

  @media screen and (max-width: 575px) {
    min-height: unset;
  }

  &:hover {
    background: ${COLORS.darks.raised.r200};
    transition: background ${transitionDuration} ease;
    div > .title {
      text-decoration: none;
      text-shadow: 0px 0px 3px ${COLORS.main.cadence}10;
      transition: text-shadow, color, ${transitionDuration} ease;
      color: ${COLORS.main.rollover};
    }
    .card-img-top {
      opacity: 1;
      transition: opacity ${transitionDuration} ease;
    }
  }
`;

export const ImageWrapper = styled.div`
	padding-bottom: 153%;
	position: relative;
    @media screen and (max-width: 575px) {
      max-height: 30vw;
      overflow: hidden;
	}
`

// Post Styles

export const CategoryPostLink = styled(Link)`
  padding: 1rem 0 0 0;
  font-size: 1.4rem;
`;

export const DatePost = styled.span`
  padding: -1rem 0 0 0;
  margin: 0 0 0 0.3em;
  font-weight: normal;
  font-size: 90%;
`;

export const FooterSeb = styled.footer`
  margin: 0;
  padding: 0.05rem 0;
  position: relative;
  padding: 0.5rem 0;
  width: 100vw;
  color: ${COLORS.mediumGrey};
  justify-content: center;
  h4 {
    font-size: 1rem;
  }

  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    position: relative;
    left: -12px;
    background: black;
    padding: 0.05rem 0;
    flex: 1;
    max-height: 55px;
  }
`;

export const FooterShareMenuDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 240px;
  box-shadow: 1px 1px 1px #00000090;
  height: 80px;
  background: white;
  border-radius: 66rem;
  left: 20px;
  position: absolute;
  padding: 0 20px;
  top: -130px;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

export const FooterButton = styled.div`
  all: unset;
  background: none;
  margin-left:1rem;
  font-size: 1rem;
  color: grey;
  margin-bottom: 1em;
  &:active,
  &:visited,
  &:hover,
  &:focus {
color: black;
  }
`;

export const GatsbyCredit = styled.h5`
  position: absolute;
  right: 2rem;
  font-size: 12px;

  a {
    color: ${COLORS.main.cadence};
  }
`;

export const CopyrightPanel = styled.h5`
  font-size: 0.98em;
  color: ${COLORS.main.cadence};
  white-space: nowrap;


  @media screen and (max-width: ${DIMENSIONS.breakpoints.mobile}px) {
    position: absolute;
    color: ${COLORS.lightGrey};
    margin: 0 auto;
    text-align:center;
  }
`;
